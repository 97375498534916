































import { Component, Vue } from "vue-property-decorator";
import { UtilsPaciente } from "@/utils/utils-paciente";
import DashEvolucionPaciente from "@/store/modules/Dashboards/Pacientes/DashEvolucionPaciente-module";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
import pacienteModule from "@/store/modules/paciente-module";
import { paciente } from "@/shared/dtos/paciente-dto";
@Component({
  components: {
    ColumnsChart: () => import("@/components/Charts/ColumnsChart.vue"),
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue")
  }
})
export default class DashComparacionMediciones extends Vue {
  public id_paciente: number = 0;
  public created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id)
      );
    }
    DashEvolucionPaciente.getDashAntesDespues(this.id_paciente);

    let paci: paciente = new paciente();
    paci.numero = this.id_paciente;
    pacienteModule.getpaciente(paci);
  }
  public get source() {
    return DashEvolucionPaciente.DashAntesDespues;
  }
  public get sourcepaci() {
    return pacienteModule.paciente;
  }
  public get GetName() {
    return UtilsPaciente.getNameApellidos(pacienteModule.paciente);
  }

  public get Imagen() {
    return UtilsPaciente.getrutaImagen(pacienteModule.paciente);
  }
}
